import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'

import SEO from 'components/seo'
import renderAst from 'components/rehyped'

const SuccessPage = ({ data }) => {
  return (
    <>
      {data && (
        <>
          <SEO noindex title='' />
          {renderAst(data.markdownRemark.htmlAst)}
        </>
      )}
    </>
  )
}

SuccessPage.noLayout = true

SuccessPage.propTypes = {
  data: PropTypes.object
}

export default SuccessPage

export const query = graphql`
  query($path: String!) {
    markdownRemark(
      frontmatter: { noLayout: { eq: true }, path: { eq: $path } }
    ) {
      htmlAst
    }
  }
`
